import { checkIfDateIsValid } from './checkIfDateIsValid'

/**
 * Use this function to convert date from BE to InputDate type
 * BE date format: YYYY-MM-DDTHH-mm-ss.SSSZ or YYYY-MM-DDTHH-mm-ss or YYYY-MM-DD
 */
export const convertStringDateToObject = (date: string) => {
  const dateOnly = date.split('T')[0]

  const isValid = checkIfDateIsValid(dateOnly)

  if (!isValid) {
    return
  }

  // Splitting date via '-' instead of new Date() to avoid timezone issues
  const dateArr = dateOnly.split('-')

  return {
    day: dateArr[2],
    month: dateArr[1],
    year: dateArr[0],
  }
}
