import * as Sentry from '@sentry/nextjs'
import { getFeatureFlagValue as flagUtil } from '@faml/faml-utilities'

export const getFeatureFlagValue = (flag: keyof Window['__FAML_FEATURE_FLAGS__'], clientKey?: string | undefined) => {
  // Added try-catch to prevent the app from crashing if the flag is not found in the config file.
  // This can happen if somehow the user got new code but got an outdated config file without the feature flag in it
  try {
    return flagUtil(flag, clientKey)
  } catch (error) {
    const featureFlags = window.__FAML_FEATURE_FLAGS__
      ? Object.keys(window.__FAML_FEATURE_FLAGS__).join(', ')
      : 'No feature flags found'

    Sentry.captureException(error, {
      tags: {
        function: 'getFeatureFlagValue',
        flag,
        featureFlags,
      },
    })
    return false
  }
}
