import * as Sentry from '@sentry/nextjs'

export const getOperationName = (query: string) => {
  try {
    const REGEX = /(?:query|mutation|subscription)\s+([_A-Za-z][_0-9A-Za-z]*)\s*(?:\(|\{)/
    const matches = query.match(REGEX)
    const operationName = matches && matches[1] ? matches[1].trim() : ''
    return operationName
  } catch (error) {
    Sentry.captureException(error, {
      tags: { function: 'getOperationName' },
    })
    return ''
  }
}
